.toc {
  margin: 2em 0;

  li {
    margin: 0.3em 0;
  }

  &.toc-list,
  .toc-list {
    list-style: none;
  }

  &.toc-list {
    padding-left: 0;
  }

  .toc-list {
    padding-left: 0;
    @include media-breakpoint-up('md') {
      padding-left: 4em;
    }
  }

  a {
    color: inherit;
  }

  a:hover {
    .toc-item-name {
      text-decoration: underline;
    }
  }

  .toc-item-number {
    display: inline-block;
    font-family: 'Courier New', Courier, monospace;
    color: #bbb;
    padding-right: 1em;
    text-align: right;
  }

  &.toc-list {
    & > li > a .toc-item-name {
      font-weight: 600;
      color: #4d4d4d;
    }
  }
}
