.instruction-video {
  .instruction-video-show-button {
    display: inline-block;
  }
  .instruction-video-hide-button {
    display: none;
  }

  .instruction-video-video {
    margin: 1em 0;
    display: none;
  }

  &.video-shown {
    .instruction-video-show-button {
      display: none;
    }
    .instruction-video-hide-button {
      display: inline-block;
    }

    .instruction-video-video {
      display: block;
    }
  }
}
