ul.cards {
  padding: 0;
}

.ui.card,
.ui.cards > .card {
  // box-shadow: 0 1px 20px 0 #00000010;
  box-shadow: none;
  background: #efefef;
}
