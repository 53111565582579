.page {
  padding: 1em 0;

  @include media-breakpoint-up(md) {
    padding: 0.5 * 45px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 0.5 * 59px 0;
  }
  @include media-breakpoint-up(xl) {
    padding: 0.5 * 73px 0;
  }
}
