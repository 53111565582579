h1 {
  font-size: 3rem;
  @include media-breakpoint-up('md') {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up('lg') {
    font-size: 5rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  letter-spacing: -0.03em;
  color: #4d4d4d;
}
